// Generated by Framer (508aa67)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {lXS0I6k3J: {hover: true}};

const cycleOrder = ["lXS0I6k3J"];

const serializationHash = "framer-4n1nh"

const variantClassNames = {lXS0I6k3J: "framer-v-xsykqa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "lXS0I6k3J", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xsykqa", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"lXS0I6k3J"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(180, 220, 128)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "0px 0px 8px 0px rgba(25, 26, 25, 0.05)", ...style}} variants={{"lXS0I6k3J-hover": {backgroundColor: "rgba(195, 255, 131, 0.99)"}}} {...addPropertyOverrides({"lXS0I6k3J-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TWFucm9wZS1yZWd1bGFy", "--framer-font-family": "\"Manrope\", \"Manrope Placeholder\", sans-serif"}}>Apply to join <motion.span style={{"--font-selector": "R0Y7TWFucm9wZS02MDA=", "--framer-font-weight": "600"}}>Paris</motion.span> beta 💌</motion.p></React.Fragment>} className={"framer-1wuwi8"} fonts={["GF;Manrope-regular", "GF;Manrope-600"]} layoutDependency={layoutDependency} layoutId={"g2_He5V28"} style={{"--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4n1nh.framer-lly6o0, .framer-4n1nh .framer-lly6o0 { display: block; }", ".framer-4n1nh.framer-xsykqa { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 10px 5px 10px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-4n1nh .framer-1wuwi8 { flex: none; height: auto; position: relative; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-4n1nh.framer-xsykqa { gap: 0px; } .framer-4n1nh.framer-xsykqa > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-4n1nh.framer-xsykqa > :first-child { margin-left: 0px; } .framer-4n1nh.framer-xsykqa > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 207
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"zqWE22PZB":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerF5nOYg3fA: React.ComponentType<Props> = withCSS(Component, css, "framer-4n1nh") as typeof Component;
export default FramerF5nOYg3fA;

FramerF5nOYg3fA.displayName = "Top Banner";

FramerF5nOYg3fA.defaultProps = {height: 29, width: 207};

addFonts(FramerF5nOYg3fA, [{explicitInter: true, fonts: [{family: "Manrope", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_A87jxeN7B.woff2", weight: "400"}, {family: "Manrope", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_A87jxeN7B.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})